import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { scriptLoader } from "../helpers/scriptLoader";
export default function embed(article) {
  if (!article) return;
  var embeds = article.getElementsByClassName("js-embed");
  if (!(embeds !== null && embeds !== void 0 && embeds.length)) return;
  var socialEmbeds = article.getElementsByClassName("js-social-embed");
  if (socialEmbeds !== null && socialEmbeds !== void 0 && socialEmbeds.length) {
    scriptLoader("https://sme.expressen.se/js/social-media-embeds.min.js").then(function () {
      socialMediaEmbeds(article, {}); // eslint-disable-line no-undef
    })["catch"]();
  }
  var iframes = article.querySelectorAll(".js-embed__container > iframe");
  if (iframes !== null && iframes !== void 0 && iframes.length) {
    var iFrameResize;
    scriptLoader("https://ext.dn.se/qs/widgets/arkku2.0/iframeresizer/iframeResizer.min.js").then(function () {
      iFrameResize = window.iFrameResize;
    }).then(function () {
      for (var i = 0, len = iframes.length; i < len; i++) {
        iFrameResize({
          log: false,
          checkOrigin: false
        }, iframes[i]);
      }
    })["catch"]();
    var arkkuIframes = {};
    var threshold = _toConsumableArray(Array(10)).map(function (d, i) {
      return (i + 0.5) / 10;
    });
    var _loop = function _loop(i) {
      var element = iframes[i];
      if (!element.parentNode.classList.contains("embed__arkku") || !element.contentWindow) return 1; // continue
      var iframeObj = {
        contentWindow: element.contentWindow,
        index: i,
        element: element,
        intersectionRatio: null
      };
      arkkuIframes[iframeObj.index] = iframeObj;
      iframeObj.observer = new window.IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          var _iframeObj$contentWin;
          var intersectionRatio = entry.intersectionRatio;
          var windowInnerWidth = window.innerWidth;
          iframeObj.intersectionRatio = intersectionRatio;
          (_iframeObj$contentWin = iframeObj.contentWindow) === null || _iframeObj$contentWin === void 0 || _iframeObj$contentWin.postMessage({
            type: "intersectionRatio",
            index: i,
            intersectionRatio: intersectionRatio,
            windowInnerWidth: windowInnerWidth
          }, "*");
        });
      }, {
        rootMargin: "0px",
        threshold: threshold
      });
      iframeObj.observer.observe(element);
    };
    for (var i = 0; i < iframes.length; i++) {
      if (_loop(i)) continue;
    }
  }
}