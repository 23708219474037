import { getWindowInnerHeight } from "../helpers/windowDimensions";
export default function lazyLoad() {
  var elements = document.getElementsByClassName("lazy-load");
  if (!elements.length) return;
  var loadOffset = getWindowInnerHeight();
  var observer = new window.IntersectionObserver(viewPortUpdate, {
    rootMargin: "".concat(loadOffset, "px 0px"),
    threshold: 0.01
  });
  for (var i = 0, len = elements.length; i < len; ++i) {
    observer.observe(elements[i]);
  }
  function viewPortUpdate(entries) {
    entries.forEach(function (entry) {
      if (entry.intersectionRatio > 0) {
        observer.unobserve(entry.target);
        loadResource(entry.target);
      }
    });
  }
  function loadResource(el) {
    var noscript = el.getElementsByTagName("noscript")[0];
    if (!noscript) return;
    noscript.parentElement.insertAdjacentHTML("beforeend", noscript.textContent);
    noscript.parentElement.removeChild(noscript);
  }
}