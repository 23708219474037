import passiveIfPossible from "../helpers/passiveIfPossible";
export default function slider(container) {
  if (!container) return;
  var itemContainer = container.getElementsByClassName("slideshow__items")[0];
  var items = itemContainer === null || itemContainer === void 0 ? void 0 : itemContainer.children;
  if (!items) return;
  var itemsCount = items.length;
  var defaultDuration = 300;
  var currentIndex = 0;
  var itemContainerWidth = itemContainer.getBoundingClientRect().width;
  var _slider = {
    slideToNext: slideToNext,
    slideToPrevious: slideToPrevious
  };
  init();
  return _slider;
  function init() {
    initSwipeControls();
    itemContainer.style.webkitTransitionDuration = itemContainer.style.transitionDuration = "".concat(defaultDuration, "ms");
  }
  function slideToNext() {
    slideToIndex(currentIndex + 1);
  }
  function slideToPrevious() {
    slideToIndex(currentIndex - 1);
  }
  function slideToIndex(index) {
    var targetIndex = clampIndex(index);
    handleSingleItemInView(targetIndex);
    currentIndex = targetIndex;
  }
  function handleSingleItemInView(index) {
    var targetPosition = getPosition(index);
    itemContainer.style.webkitTransform = itemContainer.style.transform = "translateX(".concat(targetPosition, "%)");
  }
  function getPosition(index) {
    return -(index * 100);
  }
  function clampIndex(index) {
    if (index >= itemsCount) return 0;
    if (index < 0) return itemsCount - 1;
    return index;
  }
  function initSwipeControls() {
    var start, delta;
    var notPassive = passiveIfPossible(false);
    itemContainer.addEventListener("touchstart", onStart, notPassive);
    function onStart(event) {
      var touchEvent = event.touches ? event.touches[0] : event;
      start = {
        x: touchEvent.pageX,
        y: touchEvent.pageY,
        position: getPosition(currentIndex),
        time: Date.now()
      };
      window.addEventListener("touchmove", onFirstMove, notPassive);
    }
    function onFirstMove(event) {
      window.removeEventListener("touchmove", onFirstMove, notPassive);
      // ensure swiping with one touch and not pinching
      if (event.touches.length > 1 || event.scale && event.scale !== 1) return;
      var touchEvent = event.touches[0];
      var isScrolling = Math.abs(touchEvent.pageX - start.x) < Math.abs(touchEvent.pageY - start.y);
      if (isScrolling) return;
      event.preventDefault();
      window.addEventListener("touchmove", onMove, notPassive);
      window.addEventListener("touchend", onEnd, notPassive);
      itemContainer.style.webkitTransitionDuration = itemContainer.style.transitionDuration = "0s";
    }
    function onMove(event) {
      event.preventDefault(); // prevent native scrolling

      // measure change in x and y
      var touchEvent = event.touches[0];
      delta = touchEvent.pageX - start.x;

      // we add an edge slide resistance to swipe
      if (isPastBounds()) {
        delta = delta / (Math.abs(delta) / itemContainerWidth + 1);
      }
      var targetPosition = start.position + delta / itemContainerWidth * 100;
      itemContainer.style.webkitTransform = itemContainer.style.transform = "translateX(".concat(targetPosition, "%)");
    }
    function onEnd() {
      window.removeEventListener("touchmove", onMove, notPassive);
      window.removeEventListener("touchend", onEnd, notPassive);
      var direction = !isSwipe() || isPastBounds() ? 0 : getSign(delta);
      var duration = Math.min(Date.now() - start.time, 300);
      itemContainer.style.webkitTransitionDuration = itemContainer.style.transitionDuration = "".concat(duration, "ms");
      slideToIndex(currentIndex - direction);
      start = undefined;
      delta = undefined;
    }
    function getSign(val) {
      if (val === 0) {
        return 0;
      }
      return val > 0 ? 1 : -1;
    }
    function isSwipe() {
      if (!delta) return false;
      var duration = Date.now() - start.time;
      var isQuickSwipe = parseInt(duration, 10) < 250 && Math.abs(delta) > 40;
      var isLongSwipe = Math.abs(delta) > itemContainerWidth / 2;
      return isQuickSwipe || isLongSwipe;
    }
    function isPastBounds() {
      if (delta > 0 && currentIndex === 0) return true;
      return delta < 0 && currentIndex === itemsCount - 1;
    }
  }
}