import continuousScroll from "./plugins/continuousScroll";
import didomiSettings from "./plugins/didomiSettings";
import embed from "./plugins/embed";
import lazyLoad from "./plugins/lazyLoad";
import lazyLoadStylesheets from "./plugins/lazyLoadStylesheets";
import letterAnchors from "./plugins/letterAnchors";
import search from "./plugins/search";
import siteHeader from "./plugins/siteHeader";
import slideshow from "./plugins/slideshow";
import toggleMegaMenu from "./plugins/megaMenu";
export function init() {
  lazyLoadStylesheets("lazy-stylesheet");
  lazyLoad();
  didomiSettings();
  search();
  if (window.Di.contentType === "article") {
    var articles = document.getElementsByClassName("article");
    for (var i = 0; i < articles.length; i++) {
      embed(articles[i]);
      slideshow(articles[i]);
    }
    continuousScroll();
  }
  if (window.location.pathname === "/om/") {
    letterAnchors();
  }
  if (CHANNEL_MOBILE || CHANNEL_TABLET) {
    toggleMegaMenu();
  }
  siteHeader();
}
init();