export default function init(selector) {
  if (isPreloadSupported()) return;
  var links = document.head.getElementsByClassName(selector);
  for (var i = 0, len = links.length; i < len; ++i) {
    var link = links[i];
    link.setAttribute("onload", "");
    link.rel = "stylesheet";
  }
}
function isPreloadSupported() {
  try {
    return document.createElement("link").relList.supports("preload");
  } catch (e) {
    return false;
  }
}