import closeOtherHeaderActions from "../helpers/closeOtherHeaderActions";
import debounce from "../helpers/debounce";
import { isValidSearchString } from "../helpers/isValidSearchString";
import { pushSearchEventToDataLayer } from "../helpers/pushSearchEventToDataLayer";
var FORM_BASE_CLASS = "search-form";
var TOGGLE_BASE_CLASS = "search-toggle";
var getEventData = function getEventData(event, type, query) {
  return {
    event: event,
    searchQuery: query,
    componentName: "main search",
    type: type
  };
};
function executeSearch(input) {
  if (isValidSearchString(input)) {
    pushSearchEventToDataLayer(getEventData("search submit", "submit", input));
    var url = new URL("".concat(window.Di.baseUrl, "/sok/?query=").concat(encodeURIComponent(input)));
    window.location.href = url.href;
  }
}
export default function search() {
  var toggleBtn = document.getElementsByClassName("js_search-toggle")[0];
  var searchForm = document.getElementsByClassName("js_search-form")[0];
  var inputField = document.getElementsByClassName("js_search-input")[0];
  var overlay = document.getElementsByClassName("js_site-overlay")[0];
  var interactionEventCount = 0;
  if (!toggleBtn || !inputField || !searchForm || !overlay) return;
  setupSearchForm();
  setupClearInputButton();
  function toggleSearchForm() {
    overlay.classList.toggle("hidden");
    ["active", "closed"].forEach(function (modifier) {
      searchForm.classList.toggle("".concat(FORM_BASE_CLASS, "--").concat(modifier));
    });
    toggleBtn.classList.toggle("".concat(TOGGLE_BASE_CLASS, "--active"));
  }
  function closeSearchForm() {
    searchForm.classList.remove("".concat(FORM_BASE_CLASS, "--active"));
    searchForm.classList.add("".concat(FORM_BASE_CLASS, "--closed"));
    toggleBtn.classList.remove("".concat(TOGGLE_BASE_CLASS, "--active"));
    overlay.classList.add("hidden");
  }
  function setupSearchForm() {
    toggleBtn.addEventListener("click", function () {
      if (CHANNEL_MOBILE || CHANNEL_TABLET) closeOtherHeaderActions("search");
      toggleSearchForm();
      var searchFormIsOpen = searchForm.classList.contains("".concat(FORM_BASE_CLASS, "--active"));
      if (searchFormIsOpen) {
        inputField.focus();
      }
    });
    overlay.addEventListener("click", function () {
      if (!overlay.classList.contains("hidden")) toggleSearchForm();
    });
    inputField.addEventListener("keyup", function (event) {
      return debounce(handleKeyUp(event), 300);
    });
    inputField.addEventListener("blur", function () {
      if (interactionEventCount !== 0) {
        interactionEventCount = 0;
      }
    });
    var searchSubmitBtn = searchForm.getElementsByClassName("js_search-form__button")[0];
    if (searchSubmitBtn) searchSubmitBtn.addEventListener("click", function () {
      return executeSearch(inputField.value);
    });
  }
  function handleKeyUp(event) {
    if (inputField.value && interactionEventCount === 0) {
      pushSearchEventToDataLayer(getEventData("search field interaction", "interaction"));
      interactionEventCount++;
    }
    switch (event.key) {
      case "Enter":
        executeSearch(inputField.value);
        break;
      case "Escape":
        closeSearchForm();
        break;
    }
  }
  function setupClearInputButton() {
    var clearInputBtn = searchForm.getElementsByClassName("js_search-form__clear-input")[0];
    if (!clearInputBtn) return;
    clearInputBtn.addEventListener("click", function () {
      inputField.value = "";
      inputField.focus();
    });
  }
}