import scroll from "../helpers/scroll";
var siteHeaderElm,
  siteHeaderClosed = false;
var siteHeaderHeight = 56;
export default function siteHeader() {
  if (CHANNEL_MOBILE) {
    siteHeaderElm = document.getElementsByClassName("js_site-header")[0];
    if (!siteHeaderElm) return;
    scroll.add(siteHeaderScrollMobile);
  }
  var triggerElements = document.getElementsByClassName("js_site-header__link");
  for (var i = 0, len = triggerElements.length; i < len; i++) {
    var element = triggerElements[i];
    element.addEventListener("click", pushDataLayer);
  }
}
function pushDataLayer(e) {
  if (!e.target) return;
  var href = e.target.getAttribute("href");
  window.dataLayer.push({
    event: "navigation link click",
    eventParams: {
      category: "navigation"
    },
    engagement: {
      type: "click",
      destination: {
        title: e.target.innerText || undefined,
        url: "".concat(window.location.protocol, "//").concat(window.location.host).concat(href),
        type: "internal"
      },
      component: {
        position: "Top Menu",
        name: "".concat(e.target.innerText || undefined),
        type: "top menu"
      }
    }
  });
}
function siteHeaderScrollMobile(_ref) {
  var scrollY = _ref.scrollY,
    lastScrollY = _ref.lastScrollY;
  var threshold = 10;
  if (!siteHeaderClosed && scrollY > lastScrollY) {
    siteHeaderElm.classList.remove("site-header--sticky");
    if (scrollY >= siteHeaderHeight) {
      siteHeaderElm.classList.add("site-header--up");
      siteHeaderClosed = true;
    }
  } else if (siteHeaderClosed && lastScrollY - scrollY >= threshold) {
    siteHeaderElm.classList.remove("site-header--up");
    siteHeaderElm.classList.add("site-header--sticky");
    siteHeaderClosed = false;
  }
}